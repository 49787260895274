// File app.js

// Require jquery, fixing error jquery/$ not defined
window.$ = window.jQuery = require('jquery');
require('slick-carousel');
require('smartmenus');
require('smartmenus-bootstrap-4');
require('smartmenus-keyboard');

// Slick Slider settings
$('#sliderBarangDetail').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  arrows: true,
  centerMode: false,
  focusOnSelect: true,
  asNavFor: '#sliderBarangDetailNav'
});
$('#sliderBarangDetailNav').slick({
  slidesToShow: 5,
  slidesToScroll: 1,
  dots: true,
  arrows: false,
  centerMode: false,
  focusOnSelect: true,
  asNavFor: '#sliderBarangDetail'
});